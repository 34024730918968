<template>
  <Content :route="'/dicionarios?rota=' + $route.query.id"  :action="action" title="Configurações Avançadas" @event="updateValue">
    <template #selects>
      <span class="btn-padrao" role="button" @click="goTo">
      <i
        style="background-color: #00DDEE"
        class="material-icons btn-icon"
        >settings</i>
        Dicionário
     </span>
    </template>
    <h3 style="text-align: justify;">Campos adicionais da Rota: {{ $route.query.key }}</h3>
    <div :v-if="items.length > 0" class="code-container" v-for="(field, index) in items" :key="index">
      <h5 class="label-code">{{ field.name }}</h5>
     <div class="code-action">
      <div class="code">
        <textarea :id="index" v-model="field.value[0]" @blur="saveFields(field)"></textarea>
     </div>
     </div>
    </div>
      </Content>
  </template>

<script>
import Content from '@/components/content'
import { request, api } from '@/services'
import { toast } from '@/utils'
export default {
  components: {
    Content
  },
  data () {
    return {
      isLoading: true,
      items: []
    }
  },
  computed: {
    action () {
      return { options: { label: 'Salvar', icon: 'save' } }
    }
  },
  created () {
    if (!this.$route.query.id) return toast(this, 'info', 'Página sem parâmetros', 'Página sem parâmetro da rota')
    api.get(this.$store.state.rotas.show + this.$route.query.id).then((response) => {
      this.items = response.data.object.extra_fields.filter(e => e.type_value === 'script')
    })
  },
  methods: {
    goTo () {
      this.$router.push({ name: 'Dicionarios', query: { rota: this.$route.query.id } })
    },
    saveFields (field) {
      // this.$store.dispatch('configuracoes/updateExtraFields', field)
    },
    updateValue (item) {
      const body = this.items.reduce((obj, v) => ({ ...obj, [v.key]: v.value[0] }), {})
      console.log(body)

      request.put('object/update/' + this.$route.query.id, body,
        response => {
          toast(this, 'success', 'Campos da rota', `Campo da rota ${this.$route.query.key} atualizado com sucesso`)
          console.log(response.data)
          this.$router.push({ name: 'Dicionarios', query: { rota: this.$route.query.id } })
        },
        error => {
          toast(this, 'danger', 'Campo da rota não atualizado', error)
          return Promise.reject(error)
        }
      )
    }
  }
}
</script>

  <style>
  .label-code{
      width: 15vw;
      font-weight: 600;
      padding: 2%;
  }
  .code-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      /* align-content: center; */
      justify-content: space-between;
      align-items: flex-start;
      width: 95%;
      padding: 1%;
  }

  .btn-padrao{
    position: absolute;
    position: absolute;
    left: calc(100% - 30vw);
    width: 15vw;
    display: flex;
    align-items: center;
  }

  .code-action {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 55px;
  }
  /*
  .code {
      display: flex;
      width: 75vw;
      background-color: #262626;
      height: 15vh;
      outline: none;
      padding: 1%;
  } */
  textarea {
    display: flex;
    color: #000;
    width: 85vw;
    background-color: #FFF;
    height: 15vh;
    outline: none;
    border: solid 1px lightgray;
    border-radius: 8px;
    padding: 1.5%;
  }
  </style>
